<template>
  <div class=container>


      <table class=table width="709" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of 3D Scanning Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we present 3D scanning results for a variety of scenes with interreflections, subsurface scattering and defocus. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br><router-link :to="{path:'/projects/categories/project', query:{cid:'Physics-Based Vision',pid:'Micro Phase Shifting'}}">(Back to Main Project Page) </router-link></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Ceramic Bowl</strong></td>
                    <td><div align="right"><strong>Interreflections<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/01-Masked.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/01-Masked.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center"> The bowl is made of shiny material, resulting in strong interreflections inside the bowl.</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro00-Processed.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Conv00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Conv00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Mod00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Mod00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Reconstructed shapes using Micro, conventional and modulated phase shifting, respectively. 7 input images were used for all the techniques. Micro PS achieves a high quality reconstruction. In contrast, conventional PS results in systematic errors due to interreflections. Modulated PS explicitly separates the direct and global components of light transport, and thus suffers due to low SNR of the direct component.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
              </td></tr><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro01-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro01-Processed.png" height="220" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro02-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/ceramicBowl/WhieBowl3DVis-Micro02-Processed.png" height="220" border="0"></a><br>
                      </div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center"> Renderings (from different view-points) of the reconstruction achieved using Micro PS.</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
                </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Lemon</strong></td>
                    <td><div align="right"><strong>Subsurface Scattering<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/Lemon.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/Lemon.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center"> Lemon skin is translucent, resulting in subsurface scattering. </div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonMicro.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonMicro.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonConv.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonConv.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonMod.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/lemon/LemonMod.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Reconstructed shapes using Micro, conventional and modulated phase shifting, respectively. 7 input images were used for all the techniques. Both conventional and modulated PS suffer due to low SNR of the direct irradiance component. Modulated PS requires a large number of images per frequency. With a budget of 7 images, only the unit frequency can be acquired, which is not sufficient for accurate reconstruction. Moreover, the explicit separation of direct component from the captured images further enhances the noise. The problem is especially severe for translucent materials, for which the direct component is a small fraction of the total radiance. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
                </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Marble Statue</strong></td>
                    <td><div align="right"><strong>Subsurface Scattering<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/Horse.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/Horse.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center"> This scene consists of a translucent marble statue. </div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseMicro.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseMicro.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseConv.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseConv.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseMod.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/Horse/HorseMod.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> <strong>Click on each of the thumbnail images for high-resolution images.</strong> Both conventional and modulated PS produce noisy reconstructions. Reconstruction with Micro PS is of higher quality. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
                </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Russian Dolls</strong></td>
                    <td><div align="right"><strong>Illumination Defocus<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/IMG_9919-processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/IMG_9919-processed.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>This scene consists of a bunch of Russian dolls on a table. Because the depth of the scene is larger than the depth-of-field of the projector, parts of the scene receive defocused illumination. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Micro00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Micro00-Processed.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Conv00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Conv00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Mod00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/dolls/RussianDolls3DVis-Mod00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Reconstructed shapes using Micro, conventional and modulated phase shifting, respectively. 7 input images were used for all the techniques. Shapes reconstructed using conventional and modulated PS have holes in regions of low albedo. In contrast, reconstruction using Micro PS is more complete. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
                </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Wax Bowl</strong></td>
                    <td><div align="right"><strong>Interreflections + Subsurface Scattering<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/01-Masked.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/01-Masked.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>
                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center"> This object has both interreflections and subsurface scattering. </div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro00-Processed.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Conv00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Conv00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Mod00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Mod00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Reconstructed shapes using Micro, conventional and modulated phase shifting, respectively. 7 input images were used for all the techniques. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
             </td></tr><tr>

                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro01-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro01-Processed.png" height="220" border="0"></a><br>
                          </div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro02-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/waxBowl/WaxBowl3DVis-Micro02-Processed.png" height="220" border="0"></a><br>
                      </div></td>

                    </tr>
                </tbody></table>

                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                    <tbody><tr>
                      <td> <div align="center"> Renderings (from different view-points) of the reconstruction achieved using Micro PS.</div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
               </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Metal Bowl (failure case)</strong></td>
                    <td><div align="right"><strong>Specular Interreflections<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/IMAGE.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/IMAGE.png" height="270" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene</strong></div></td>

                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>This is a failure case. The bowl is made of specular metal, resulting in strong and high-frequency interreflections. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Micro00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Micro00-Processed.png" width="200" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Micro PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Conv00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Conv00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Conventional PS </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Mod00-Processed.png"><img src="https://cave.cs.columbia.edu/old/projects/micro_phase_shifting/images/metalBowl/MetalBowl3DVis-Mod00-Processed.png" width="200" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Modulated PS </strong></div></td>
                    </tr>
                </tbody></table>

                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td> Due to high-frequency specular interreflections, reconstructed shapes using all the schemes have large holes and errors.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table>
                </td></tr><tr>
                </tr>
          </tbody></table></td>
          </tr>
          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
}
.table{
        margin:auto;
}
</style>